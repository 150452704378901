import React, { useState, useContext, useEffect } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { faForward } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { Link, navigate } from 'gatsby'
import { AuthContext } from '../../pageContext'
import './profile.scss'

const Profile = () => {
  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [company, setCompany] = useState('')
  const [industry, setIndustry] = useState('')
  const [title, setTitle] = useState('')
  const [salary, setSalary] = useState('')
  const [education, setEducation] = useState('')
  const { actions, state } = useContext(AuthContext)

  const handleSkip = (event) => {
    event.preventDefault()
    navigate('/portal', { replace: false })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (
      country !== '' &&
      region !== '' &&
      company !== '' &&
      industry !== '' &&
      title !== '' &&
      salary !== '' &&
      education !== ''
    ) {
      await axios.post(
        'https://akta-app-api.herokuapp.com/users/complete-profile',
        {
          userID: state.userId,
        }
      )
    }
    try {
      const profile = {
        country: country,
        region: region,
        company: company,
        userID: state.userId,
        industry: industry,
        title: title,
        salary: salary,
        education: education,
      }
      await axios.post(
        'https://akta-app-api.herokuapp.com/profile/save',
        profile
      )
    } catch (error) {
      console.log(error)
    }

    navigate('/portal', { replace: true })
  }

  const loadProfile = async () => {
    try {
      const response = await axios.post(
        'https://akta-app-api.herokuapp.com/profile/load',
        {
          userID: state.userId,
        }
      )

      if (response.data) {
        const { company, country, education, industry, region, salary, title } =
          response.data
        setCountry(country)
        setCompany(company)
        setEducation(education)
        setIndustry(industry)
        setRegion(region)
        setSalary(salary)
        setTitle(title)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    loadProfile()
  }, [])

  return (
    <div className="profile">
      <h2>Complete Your Profile</h2>
      <form className="form">
        <div className="row">
          <label htmlFor="country">Country</label>
          <CountryDropdown
            name="country"
            value={country}
            onChange={(val) => setCountry(val)}
          />
        </div>
        <div className="row">
          <label htmlFor="region">Region</label>
          <RegionDropdown
            name="region"
            country={country}
            value={region}
            onChange={(val) => setRegion(val)}
          />
        </div>
        <div className="row">
          <label htmlFor="company">Company</label>
          <input
            id="company"
            placeholder="Company Name"
            type="text"
            onChange={(event) => setCompany(event.target.value)}
            value={company}
          />
        </div>
        <div className="row">
          <label htmlFor="industry">Industry</label>
          <input
            id="industry"
            placeholder="Industry"
            type="text"
            onChange={(event) => setIndustry(event.target.value)}
            value={industry}
          />
        </div>
        <div className="row">
          <label htmlFor="title">Job Title</label>
          <input
            id="title"
            placeholder="Job Title"
            type="text"
            onChange={(event) => setTitle(event.target.value)}
            value={title}
          />
        </div>
        <div className="row">
          <label htmlFor="salary">Salary</label>
          <select
            id="salary"
            name="salary"
            onChange={(event) => {
              setSalary(event.target.value)
            }}
            value={salary}
          >
            <option value="Secondary">Primary</option>
            <option value="Secondary">Secondary</option>
            <option value="Secondary">Bachelors</option>
            <option value="Secondary">Masters</option>
            <option value="Secondary">Doctorate</option>
          </select>
        </div>
        <div className="row">
          <label htmlFor="education">Education</label>
          <select
            id="education"
            name="education"
            onChange={(event) => {
              setEducation(event.target.value)
            }}
            value={education}
          >
            <option value="Primary">Primary</option>
            <option value="Secondary">Secondary</option>
            <option value="Secondary">Secondary</option>
            <option value="Masters">Masters</option>
            <option value="Masters">Doctorate</option>
          </select>
        </div>
        <div className="buttonRow">
          <button className="skip" onClick={handleSkip}>
            <FontAwesomeIcon icon={faForward} fixedWidth color="white" />
            <span className="skipText">Skip</span>
          </button>
          <button className="submitProfile" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default Profile
